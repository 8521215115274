const togglePasswordVisibility = document.querySelector('#show-password-link');
const passwordInput = document.querySelector('#password');

togglePasswordVisibility.addEventListener('click', function(e) {
  e.preventDefault();

  if (this.firstElementChild.classList.contains('fa-eye-slash')) {
    passwordInput.type = 'password';
    this.firstElementChild.classList.remove('fa-eye-slash');
    this.firstElementChild.classList.add('fa-eye');
  } else {
    passwordInput.type = 'text';
    this.firstElementChild.classList.remove('fa-eye');
    this.firstElementChild.classList.add('fa-eye-slash');
  }
});

export { togglePasswordVisibility };