const GDPR_KEY = '__sw_gdpr_consent__';
const gdprConsent = localStorage.getItem(GDPR_KEY);
const gdprCheckbox = document.getElementById('gdpr_cookie_checkbox');
const gdprLabel = document.getElementById('gdpr_cookie_accept');

if (gdprConsent) {
  gdprLabel.remove();
}

gdprCheckbox.addEventListener('click', () => {
  const gdprConsentAccepted = localStorage.getItem(GDPR_KEY);

  if (gdprConsentAccepted) {
    localStorage.removeItem(GDPR_KEY);
  } else {
    localStorage.setItem(GDPR_KEY, 1);
  }
});

export { gdprCheckbox, gdprLabel };
