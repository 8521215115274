export const isVisible = (el) =>
  !!(el.offsetWidth || el.offsetHeight || el.getClientRects().length);
export const forEach = (array, callback, scope) => {
  for (let i = 0; i < array.length; i += 1) {
    callback.call(scope, i, array[i]);
  }
};
export const getCookie = (name) => {
  const parts = document.cookie.split(`${name}=`);

  if (parts.length === 2) {
    return parts.pop().split(';').shift();
  }

  return false;
};
export const expireCookie = (cName) => {
  document.cookie = `${encodeURIComponent(cName)}=deleted; expires=${new Date(
    0
  ).toUTCString()}`;

  return true;
};
export const hasOwn = (obj, key) => Object.hasOwnProperty.call(obj, key);
export const isEmpty = (obj) => Object.keys(obj).length === 0;
export const serialize = (obj, prefix) => {
  const str = [];
  const objectKeys = Object.keys(obj);

  objectKeys.forEach((key) => {
    let param = '';

    if (hasOwn(obj, key)) {
      const k = prefix ? `${prefix}[${key}]` : key;
      const v = obj[key];

      if (typeof v === 'object') {
        param = serialize(v, k);
      } else {
        param = `${encodeURIComponent(k)}=${encodeURIComponent(v)}`;
      }

      str.push(param);
    }
  });

  return str.join('&');
};
export const getJsonForm = (form) => {
  const formElements = Array.from(form.elements);
  /* @TODO Check for textarea values and radio */
  return formElements.reduce((obj, input) => {
    if (!input.name) return obj;
    if (!obj[input.name]) {
      return { ...obj, [input.name]: input.value };
    }

    return obj;
  }, {});
};
export const autoHideNotification = (
  time,
  selector = '#success',
  callback = () => {}
) => {
  if ($(selector).is(':visible')) {
    setTimeout(() => {
      $(selector).fadeOut('slow', () => {
        callback();
      });
    }, time);
  }
};
export const fadeOutNotification = (selector) => {
  // const notification = document.querySelector(selector);

  if ($(selector).is(':visible')) {
    $(selector).fadeOut('slow');
  }
};
export const fadeInNotification = (selector) => {
  // const notification = document.querySelector(selector);

  if ($(selector).not(':visible')) {
    $(selector).fadeIn('slow');
  }
};
export const updateText = (selector, message = false) => {
  const notification = document.querySelector(selector);
  const originalText = notification.textContent;

  if (message) {
    notification.textContent = message;
  }

  return originalText;
};
export const notify = (selector, textSelector, message, time = 3000) => {
  const originalText = updateText(textSelector, message);

  fadeInNotification(selector);

  if (time > 0) {
    autoHideNotification(time, selector, () => {
      updateText(textSelector, originalText);
    });
  }
};
export const notice = (message, time) => {
  const selector = '#notify';

  notify(selector, `${selector} span`, message, time);
};
export const error = (message, time) => {
  const selector = '#error';

  notify(selector, `${selector} span`, message, time);
};
export const success = (message, time) => {
  const selector = '#success';

  notify(selector, `${selector} span`, message, time);
};

export const populateSettingsForm = (FORM, data) => {
  FORM.find('.alrt-1, .alrt-2').removeClass('alrt-1 alrt-2');
  FORM.find('[name="formats[]"]:checked').click().prop('checked', false);
  FORM.find('[name="name"]').val(data.name);
  const settingKeys = Object.keys(data.settings);

  settingKeys.forEach((key) => {
    const selector = `[name="settings[${key}]"]`;

    if (key === 'output-language') {
      return false;
    }

    const EL = FORM.find(selector);

    if (EL.length === 0) {
      return false;
    }

    switch (EL[0].tagName) {
      case 'SELECT':
        EL.find(`option[value="${data.settings[key]}"]`)
          .attr('selected', 'selected')
          .prop('selected', true);
        break;

      default:
        if (EL[0].type === 'text') {
          EL.val(data.settings[key]);
        } else if (EL[0].type === 'checkbox') {
          EL.attr('checked', 'checked').prop('checked', true);
        }
        break;
    }

    const el = EL.get(0);
    const ev = document.createEvent('Event');

    ev.initEvent('change', true, false);
    el.dispatchEvent(ev);

    return true;
  });

  if (!data.formats) return;

  for (let i = 0; i < data.formats.length; i += 1) {
    FORM.find(`[name="formats[]"][value="${data.formats[i]}"]`)
      .click()
      .prop('checked', true);
  }

  if (
    hasOwn(data.settings, 'scc-roll-up') &&
    data.settings['scc-roll-up'] === 'yes'
  ) {
    FORM.find('.scc-roll-up-yes').removeClass('hide');
    FORM.find('.scc-roll-up-no').addClass('hide');
    FORM.find('.scc-roll-up-line-count').prop('disabled', false);
  } else {
    FORM.find('.scc-roll-up-yes').addClass('hide');
    FORM.find('.scc-roll-up-no').removeClass('hide');
    FORM.find('.scc-roll-up-line-count').prop('disable', true);
    FORM.find('.scc-roll-up-line-count').val(3);
  }
};
export function toggleOptionsDiv() {
  const label = this.parentNode;
  const nextElement = label.nextElementSibling;

  if (!nextElement) return;
  if (nextElement.tagName !== 'DIV') return;

  nextElement.classList.toggle('hide', !this.checked);
}

export const standardCopyToClipboard = (str, container) => {
  const wrapper = document.createElement('div');
  const textarea = document.createElement('textarea');
  const button = document.createElement('button');

  button.innerHTML = '<i class="fa fa-clipboard" aria-hidden="true"></i>';
  textarea.value = str;
  textarea.setAttribute('readonly', '');
  button.classList.add('btn1');
  wrapper.classList.add('copy-paste-code');

  button.addEventListener('click', () => {
    textarea.select();
    const copy = document.execCommand('copy');

    if (copy) {
      success('Caption options copied to the clipboard');
    }
  });
  wrapper.appendChild(textarea);
  wrapper.appendChild(button);
  container.appendChild(wrapper);
};

export const copyToClipboard = (str, container) => {
  navigator.permissions.query({ name: 'clipboard-write' }).then((result) => {
    if (result.state === 'granted' || result.state === 'prompt') {
      navigator.clipboard.writeText(str).then(
        () => success('Caption options copied to the clipboard'),
        () => {
          error(
            'Your browser sucks, click the button next to the json to copy the json'
          );
          standardCopyToClipboard(str, container);
        }
      );
    } else {
      standardCopyToClipboard(str, container);
    }
  });
};

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';

  return `${s.charAt(0).toUpperCase()}${s.slice(1)}`;
};
export const parseHTML = (str, multipleNodes = false) => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = str.trim();

  return multipleNodes ? tempDiv.childNodes : tempDiv.firstChild;
};

const Utils = {
  isVisible,
  getCookie,
  forEach,
  expireCookie,
  hasOwn,
  isEmpty,
  serialize,
  getJsonForm,
  autoHideNotification,
  fadeOutNotification,
  fadeInNotification,
  updateText,
  notify,
  notice,
  error,
  success,
  populateSettingsForm,
  toggleOptionsDiv,
  standardCopyToClipboard,
  copyToClipboard,
  capitalize,
  parseHTML,
};

export default Utils;
