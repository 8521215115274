import Utils from '../modules/utils';
import '../modules/gdpr';
import '../modules/showpassword';

const loginContainer = document.querySelector('#login_form');
const forgotPasswordContainer = document.querySelector('#forgotpw_form');
const resetPasswordSuccess = document.querySelector('.reset-email-success');
const forgotPasswordForm = document.querySelector('form#frmSend');
const forgotPasswordFormButton = document.querySelector('#send');
const forgotPasswordLinks = Array.from(document.querySelectorAll('a.forgotpw'));
const resetEmailText = document.querySelector('#signup-email');
const resendEmail = document.querySelector('#resend-email');
const resetEmailResponse = document.querySelector('#resend-response');
const toggleForgotPassword = e => {
  e.preventDefault();
  const { display } = forgotPasswordContainer.style;
  const isForgotPasswordVisibile = display !== 'none';

  if (isForgotPasswordVisibile) {
    loginContainer.style.display = 'block';
    forgotPasswordContainer.style.display = 'none';
  } else {
    loginContainer.style.display = 'none';
    forgotPasswordContainer.style.display = 'block';
  }
};

resendEmail.addEventListener('click', e => {
  e.preventDefault();
  resetEmailResponse.style.display = 'none';
  forgotPasswordFormButton.click();
  resetEmailResponse.style.display = 'block';
});

forgotPasswordForm.addEventListener('submit', async e => {
  e.preventDefault();
  Utils.fadeOutNotification('div.error_txt');

  try {
    const data = await fetch(forgotPasswordForm.action, {
      method: 'POST',
      body: new FormData(forgotPasswordForm),
    }).then(async response => response.json());
    const { error, email } = data;

    if (error) {
      Utils.notify('div.error_txt', 'div.error_txt', error, -1);
    } else {
      forgotPasswordContainer.style.display = 'none';
      resetPasswordSuccess.style.display = 'block';
      resetEmailText.textContent = email;
    }
  } catch (error) {
    Utils.notify('div.error_txt', 'div.error_txt', error, -1);
  }
});

forgotPasswordLinks.forEach(a =>
  a.addEventListener('click', toggleForgotPassword)
);
